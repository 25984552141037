import React from 'react';
import { useNavigate } from 'react-router-dom';

const LessonSelection = () => {
  const navigate = useNavigate();

  const handleLessonSelect = (lessonNumber: string) => {
    navigate(`/lesson/${lessonNumber}`);
  };

  const numberOfLessons = 5;
  return (
    <div className="mb-4">
        {Array.from({ length: numberOfLessons }, (_, index) => (
          <button
            key={index}
            className="bg-primary text-white p-2 rounded-lg m-2"
            onClick={() => handleLessonSelect((index + 1).toString())}
          >
            Lesson {index + 1}
          </button>
        ))}
    </div>
  );
};

export default LessonSelection;