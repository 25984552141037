import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { EmotionColor } from '../types/enums';
import { Answer, Question as QuestionType, Option } from '../types/types';
import Hint from './Hint';

interface QuestionProps {
  question: QuestionType;
  handleAnswer: (answer: Answer) => void;
}

function shuffleArray(array: Array<any>) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

const Question: React.FC<QuestionProps> = ({ question, handleAnswer }) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [shuffledOptions, setShuffledOptions] = useState<Option[]>([]);

  const { word, options, hint } = question;
  
  useEffect(() => {
    setShuffledOptions(shuffleArray(options));
    setSelectedOption(null);
  }, [options]);
  
  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    const answer:Answer = {
      selectedOption: option,
      question: question,
    }
    setTimeout(()=>{
      handleAnswer(answer);
    }, 800);
  };

  return (
    <div className="bg-foreground shadow-lg p-6 rounded-lg text-center max-w-sm w-screen mt-24">
      <h2 style={{ color: EmotionColor[word.emotionColor as unknown as keyof typeof EmotionColor] }} className="text-4xl font-semibold">
        {word.russian}
      </h2>
      <h3 className="text-lg mb-6">
       ({word.phonetic})
      </h3>
      <ul className="grid grid-cols-2 grid-rows-2 gap-4 h-full w-full">
        {shuffledOptions.map((option, index) => (
          <li
            key={index}
            className={clsx(
              'p-8 rounded-lg cursor-pointer transition-colors duration-300',
              {
                'cursor-not-allowed': selectedOption !== null,
                'bg-gray-200': !selectedOption !== null,
                'bg-green-300': selectedOption !== null && option.isCorrect,
                'bg-red-300': selectedOption !== null && !option.isCorrect && selectedOption === option,
                'bg-gray-400': selectedOption !== null && option !== selectedOption && !option.isCorrect,
              }
            )}
            onClick={() => handleOptionClick(option)}
          >
            {option.hebrew}
          </li>
        ))}
      </ul>
      <div className='flex flex-col gap-2 justify-center items-start mt-8'>
        <h2 className='text-xl font-semibold text-amber-600'>Hints:</h2>
        {hint.inContext && <Hint title="in context" text={hint.inContext} />}
        {hint.association && <Hint title="association" text={hint.association} />}
      </div>
    </div>
  );
};

export default Question;