import React from 'react';
import { useParams } from 'react-router-dom';
import Question from './Question';
import { Link } from "react-router-dom";
import useLesson from '../customHooks/useLesson';

const Lesson = () => {
  const { lessonId } = useParams<{ lessonId: string }>();

  const { loading,
    error,
    question,
    totalQuestions,
    currentQuestionNumber,
    answerQuestion,
    correctAnswers,
    incorrectAnswers
  } = useLesson(lessonId);

  if(loading) {
    return <div>Loading...</div>
  }
  if(error) {
    return <div>Error: {error}</div>
  }

  return (
    <div>
      <Link to='/' className='absolute top-4 left-4 bg-primary p-2 rounded-md text-white'>{"<"}</Link>
      <h1 className="text-4xl font-bold text-primary mb-4 text-center">{`Lesson ${lessonId}`}</h1>
      <h2 className="text-2xl font-semibold text-center">{`${currentQuestionNumber} out of ${totalQuestions}`}</h2>
      <div className='flex gap-4 justify-center'>
        <h3 className='text-primary'>{`correct: ${correctAnswers.length}`}</h3>
        <h3 className='text-red-500'>{`incorrect: ${incorrectAnswers.length}`}</h3>
      </div>
      {question && (
        <Question
          question={question}
          handleAnswer={answerQuestion}
        />
      )}
    </div>
  );
};

export default Lesson;