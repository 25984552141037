import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LessonSelection from './components/LessonSelection';
import Lesson from './components/Lesson';

const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-background text-neutral flex flex-col items-center p-4">
        {/* <h1 className="text-4xl font-bold text-primary mb-4">Uchy</h1> */}
        <Routes>
          <Route path="/" element={<LessonSelection />} />
          <Route path="/lesson/:lessonId" element={<Lesson />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;