import { useEffect, useState } from 'react';
import { shuffleArray } from '../lib/utils';
import useFetch from './useFetch';
import { Answer, Lesson, Question } from '../types/types';
  
const useLesson = (lessonId: string | undefined) => {
  const { data: lesson, loading, error } = useFetch<Lesson>(`/lessons/${lessonId}.json`)

  const [questions, setQuestions] = useState<Question[]>([]);
    const [correctAnswers, setCorrectAnswers] = useState<Answer[]>([]);
    const [incorrectAnswers, setIncorrectAnswers] = useState<Answer[]>([]);
    const [questionIndex, setQuestionIndex] = useState<number>(0);

  useEffect(() => {
    if (lesson?.questions?.length) {
        const shuffledQuestions = shuffleArray(lesson.questions);
        setQuestions(shuffledQuestions);
    }

  }, [lesson]);

  const answerQuestion = (answer: Answer) => {
    if(answer.selectedOption.isCorrect) {
        setCorrectAnswers([...correctAnswers, answer]);
    } else {
        setIncorrectAnswers([...incorrectAnswers, answer]);
    }
    setQuestionIndex(questionIndex + 1);
  }

  return {
    loading,
    error,
    question: questions[questionIndex],
    totalQuestions: (lesson?.questions?.length || 0),
    currentQuestionNumber: questionIndex + 1, 
    answerQuestion,
    correctAnswers,
    incorrectAnswers,
  };
};

export default useLesson;
