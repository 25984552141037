// emotionColor.ts
export enum EmotionColor {
  Confidence = "#5A8DB6", // Soft Muted Blue
  Joy = "#CDAA3A",        // Goldenrod Yellow
  Love = "#E88EAC",       // Muted Rose Pink
  Sadness = "#4B5C94",    // Muted Indigo
  Fear = "#944343",       // Deep Crimson
  Anger = "#7A5230",      // Muted Chocolate Brown
  Calm = "#76C7A0",       // Muted Teal
  Community = "#B36A2E",  // Soft Terracotta
  Nostalgia = "#9D8FB6",  // Muted Lavender
  Anticipation = "#A14E78" // Dusky Magenta
}

  export enum WordCategory {
    Noun = "שם עצם",
    Verb = "פועל",
    Adjective = "תואר",
    Preposition = "מילת יחס",
    Adverb = "תואר הפועל"
}