import { useState, useEffect } from 'react'

function useFetch<T>(url: string, options?: RequestInit) {
  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(null)

      try {
        const response = await fetch(url, options)

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`)
        }

        const result: T = await response.json()
        setData(result)
      } catch (error) {
        setError(`Fetch error: ${error}`)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [url, options]) // Re-run the effect if the URL or options change

  return { data, loading, error }
}

export default useFetch