import clsx from 'clsx';
import React, { useState } from 'react'
import { IoMdArrowDropdown } from "react-icons/io";

type Props = {
    text: string;
    title: string;
}

export default function Hint({ text, title }: Props) {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <div 
            className='flex flex-col justify-center items-center gap-1 bg-amber-100 rounded-lg w-full' 
            onClick={() => setIsOpen(!isOpen)}>
            <div className='flex text-amber-600 justify-center items-center gap-1'>
                <h3>{title}</h3>
                <IoMdArrowDropdown className={clsx(
                    'relative top-[2px] transition-all duration-300',
                    {   
                        'transform rotate-180': isOpen,
                        'transition-transform duration-300': isOpen,
                    }
                )}/>
            </div>
            <div className={clsx(
                'h-full overflow-hidden transition-all duration-300',
                {
                    "h-8": isOpen,
                    "h-1": !isOpen,
                }
            )
            }>
                <p>{text}</p>
            </div>
        </div>
    )
}